import { type ReactNode } from "react";

import cn from "../../util/cn.js";

interface Props {
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: ReactNode;
  id?: string;
}

const Header = ({ className, children, level = 1, id }: Props) => {
  const Node = `h${level}` as "h1" | "h2" | "h3" | "h4" | "h5";
  return (
    <Node
      className={cn(
        {
          "line-h text-3xl font-medium leading-snug tracking-tight md:text-4xl dark:text-white font-serif":
            level === 1,
          "text-2xl font-medium leading-snug tracking-tight md:text-3xl dark:text-white":
            level === 2,
          "text-xl font-medium leading-snug tracking-tight dark:text-white": level === 3,
          "text-lg font-medium leading-snug dark:text-white": level === 4,
          "text-md font-medium leading-snug dark:text-white": level === 5,
          "text-sm font-medium leading-snug dark:text-white": level === 6,
        },
        className
      )}
      id={id}
    >
      {children}
    </Node>
  );
};

export default Header;
